<template>
  <div>
    <BCard>
      <ValidationObserver ref="validation-observer">
        <b-form
            @submit.prevent="setUserPasswords"
        >
          <div class="row">

            <div class="col-6">
              <ValidationProvider name="E-mail" rules="required" v-slot="{errors}">
                <b-form-group
                    label="E-mail"
                    label-for="login"
                >
                  <b-form-input
                      id="login"
                      type="email"
                      placeholder="Введите E-mail"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>

            <div class="col-6">
              <b-form-group
                  label="Пароль"
                  label-for="password"
              >
                <b-form-input
                    id="password"
                    v-model="password"
                    type="text"
                    placeholder="Введите пароль"
                />
              </b-form-group>
            </div>

          </div>


          <b-button
              type="submit"
              variant="success"
              class="float-right"
          >
            Сохранить
          </b-button>
        </b-form>
      </ValidationObserver>
    </BCard>
  </div>
</template>

<script>
import {BCard, BButton, BForm, BFormGroup, BFormInput, BInputGroupAppend} from "bootstrap-vue";
import api from "@/services/api";

export default {
  name: "AppSettings",
  components: {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend
  },
  data() {
    return {
      email: '',
      password: '',
      passwordFieldType: 'password',
    }
  },

  async mounted() {
    await this.getUser()
  },

  methods: {
    async getUser() {
      await api.settings.fetchSettings()
          .then(res => {
            this.email = res.data.email
            this.password = res.data.password
          })
          .catch(error => {
            console.error(error)
          })
    },

    setUserPasswords() {
      const body = {
        email: this.email,
        password: this.password
      }
      api.settings.setUserDetails(body)
          .then(res => {
            this.email = res.data.email
            this.password = res.data.password
          })
          .catch(error => {
            console.error(error)
          })
    },
  },


}
</script>

<style lang="scss" scoped>

.form-group {
  position: relative;
}

.input-group-append {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35.984px;
  width: 35.984px;
  border-radius: .257rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #283046;
  //padding: .5rem 1rem;
  position: absolute;
  top: 38%;
  right: 1px;
  cursor: pointer;
  padding: 11px;
}

</style>
